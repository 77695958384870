import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { getCurrentUser } from "../../utils/auth"
import { setUserSettings, getUserSettings } from "../../graphql/queries"
import { Amplify, API, graphqlOperation } from "aws-amplify"
import awsExports from "../../../static/scripts/aws-exports"

const UserInfo = () => {
  const user = getCurrentUser()

  const [selectedSettings, setSelectedSettings] = useState({
    data: {
      getUserSettings: [],
    },
  })

  const saveUserSettings = arr => {
    try {
      if (arr.length <= 0) return
      Amplify.configure(awsExports)
      var operation = graphqlOperation(setUserSettings)
      operation.authMode = "AMAZON_COGNITO_USER_POOLS"
      operation.variables = { sources: arr }
      API.graphql(operation)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    async function fetchUserSettings() {
      try {
        if (selectedSettings.data.getUserSettings.length > 0) return
        //    setIsLoading(true)
        Amplify.configure(awsExports)
        var operation = graphqlOperation(getUserSettings)
        operation.authMode = "AMAZON_COGNITO_USER_POOLS"
        const result = await API.graphql(operation)
        setSelectedSettings(result)
        console.log(result)
        //setResult(data)  setIsLoading(false)
      } catch (e) {
        console.error(e)
      }
    }
    fetchUserSettings()
  })

  console.log("user:", user)
  return (
    <div id="profile">
      <div class="section-header">
        <div class="profile-photo">
          <i class="fas fa-user"></i>
        </div>
      </div>
      <div class="profile-information">
        <div class="info-label">
          <div class="the-label">البريد الإلكتروني</div>
          <div class="the-value">{user.email}</div>
        </div>
        <div class="info-label">
          <div class="the-label">تحويل للوضع الليلي</div>
          <div class="the-value">
            <div class="switch-theme" onclick="switchTheme()">
              <span>
                <i class="fas fa-moon"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="info-label">
          <div class="the-label">طريقة العرض</div>
          <div class="the-value">
            <div
              class="change-layout floatleft"
              data-layout="grid"
              onclick="changeLayout()"
            >
              <i class="fas fa-th-large"></i>
              <i class="fas fa-th-list"></i>
            </div>
          </div>
        </div>
        <div class="info-label">
          <div class="the-label">مصادري</div>
          <div class="the-value">
            <Link to="/sources">تعديل المصادر</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export function setTheme(theme) {
  const script = document.createElement("script")
  script.type = "text/javascript"
  script.text = `switchForm(${formName});`
  document.body.appendChild(script)

  this.setState({ theme: theme })
}

export function setLayout(layout) {
  const script = document.createElement("script")
  script.type = "text/javascript"
  script.text = `switchForm(${formName});`
  document.body.appendChild(script)

  this.setState({ layout: layout })
}

export default UserInfo
